.blog_bg{
    width: 100%;
    height: 832px;
    background: url('../../assets/bg/blog_bg.png') no-repeat center center / cover;
    padding: 120px 0 144px 0;
}

.blog_wrapper{
    display: flex;
    justify-content: center;
}

.maximize{
    width: 396px;
    height: 568px;
    padding: 40px;
    background: #fff;
    border-radius: 0 16px 16px 0;
}

.maximize_subtitle{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #919EAB;
}

.maximize_title{
    font-weight: 700;
    font-size: 34px;
    line-height: 54px;
    color: #212B36;
    margin-top: 8px;
}

.maximize_descr{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #637381;
    margin-top: 8px;
}

.blog_img{
    border-radius: 16px 0 0 16px;
}

.maximize_autor{
    display: flex;
    gap: 8px;
    margin-top: 193px;
    align-items: center
}

.maximize_autor_name{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #212B36;
}

.blog_card_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 64px;
    margin-top: 80px;
}

.blog_cards{
    display: flex;
    flex-wrap: wrap;
    width: 61%;
    gap: 32px;
}

.blog_card{
    width: 357px;
    height: 482px;
    padding: 40px;
}

.blog_subtitle{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
}

.blog_title{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    margin-top: 16px;
}

.blog_autor{
    display: flex;
    align-items:center;
    margin-top: 218px;
    gap: 8px;
}

.blog_card_name{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #FFFFFF;
}

.categories{
    width: 342px;
}

.categories_input{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 0px 14px 14px;
    gap: 8px;
    width: 342px;
    height: 54px;
    background: rgba(145, 158, 171, 0.08);
    border-radius: 8px;
    border: none;
}

.categories_title{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #212B36;
    margin-top: 40px;
}

.categories_ul{
    margin: 16px 0 0 20px;
}

.categories_ul > li::marker {
    color: #FA541C;
    cursor: pointer;
}

.resent_card{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    gap: 16px;
}

.resent_card_title{
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #212B36;
}

.resent_card_date{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #919EAB;
    margin-top: 4px;
}

.popular_wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 24px;
}

.popular_item{
    background: rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #637381;
}

.popular_add{
    margin-top: 40px;
    width: 342px;
    height: 342px;
    background: url('../../assets/bg/blog_card9.svg') no-repeat center center/ cover;
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.popular_add_title{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #22B8CF;
}

.popular_add_sub{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 8px;
}

.popular_add_btn{
    padding: 6px 16px;
    width: 87px;
    height: 36px;
    background: #FA541C;
    border-radius: 8px;
    margin-top: 24px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}