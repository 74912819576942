.service_wrapper{
    width: 100%;
    height: 655px;
    background: url('../../assets/bg/service_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -3;
}

.text_wrapper {
    text-align: center;
    margin-top: 200px;
    color: #FFFFFF;
}

.service_header {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
}

.service_text {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin-top: 30px;
    opacity: 0.72;
}

.search_form_button {
    margin-top: 40px;
    background: rgba(145, 158, 171, 0.08);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    align-items: center;
    padding: 8px;
    gap: 20px;
    width: 760px;
    height: 58px;
    margin-left: 25%;
}

.service_input_email, .service_input_website{
    position: relative;
    align-items: center;
    background: transparent;
    border-radius: 8px;
    border: none;
    padding-right: 14.8%;
    padding-left: 50px;
    color: #919EAB;
}

.search_form_button label > img {
    position: absolute;
    z-index: 2;
    top: 19.5px;
    bottom: 10px;
}

.service_img_email {
    left: 25px;
}

.service_img_divider {
    left: 328px;
    bottom: 50px;
}

.service_img_website{
    right: 390px;
}

.service_button_analyse {
    background: #FA541C;
    border-radius: 8px;
    align-items: center;
    padding: 11px 22px;
    border: none;
}


.service_content_wrapper {
    margin-top: 300px;
    display: flex;
    justify-content: space-around;
}

.service_content_header {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #212B36;
    margin-bottom: 25px;
}

.service_content_text {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #637381;
    margin-bottom: 25px;
}

.service_lists {
    margin-bottom: 40px;
}

.service_lists li {
    padding-bottom: 10px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #212B36;
    margin-left: 5%;
}

.service_content_button {
    cursor: pointer;
    display: flex;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;
    width: 50%;
}

.service_learn {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #212B36;
    padding: 11px 23px 11px 22px;
}

.service_include {
    margin-top: 250px;
}

.service_include_header {
    text-align: center;
}

.service_include_header h2 {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #212B36;
    margin-bottom: 25px;
}

.service_include_header p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #637381;
}

.service_include_icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(75% + 80px);
    text-align: center;
    margin-top: 80px;
    margin-left: 10%;
}

.service_include_icons div {
    width: 33%;
}

.service_include_icons > div {
    margin-bottom: 120px;
}

.service_include_icons h3 {
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #212B36;
    margin-top: 40px;
}

.service_include_icons p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #637381;
    margin-top: 18px;
}

.benefits_wrapper {
    background: #F9FAFB;
    width: 100%;
}
.benefits_header {
    text-align: center;
}

.benefits_header h2 {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #212B36;
    padding-top: 120px;
}

.benefits_header p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #637381;
    margin-top: 25px;
}

.management_wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 120px;
}

.management_wrapper h2 {
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #212B36;
}

.management_wrapper p {
    margin-bottom: 80px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 26px;
    color: #637381;
}

.circle_wrapper {
    display: flex;
    gap: 16px;
    margin-bottom: 10px;
}

.circle_wrapper_center_right, .circle_wrapper_center_left {
    display: flex;
    gap: 16px;
    margin-bottom: 10px;
}

.circle_wrapper_center_right {
    margin-left: 10%;
}

.circle_wrapper_center_left {
    margin-left: -10%;
}

.circle_text_center_left {
    margin-left: -10%;
}

.circle_text_center_right {
    margin-left: 10%;
}

.works_wrapper {
    background: #161C24;
}

.works_wrapper h3 {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
}

.works_wrapper p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #FFFFFF;
    opacity: 0.72;
}

.works_header_wrapper {
    text-align: center;
}

.works_header_wrapper h2 {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #FFFFFF;
    padding-top: 130px;
}

.works_header_wrapper p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    opacity: 0.72;
    margin-top: 25px;
}

.works_step_orange {
    text-align: center;
    margin: 85px 0 4px 0;
    width: 101.3%;
}

.works_step_yellow {
    text-align: center;
    margin: 7px 0 4px 0;
    width: 101.3%;
}

.works_step_blue {
    text-align: center;
    margin: 7px 0 4px 0;
    width: 94.7%;
}

.works_step_green {
    text-align: center;
    margin: 7px 0 4px 0;
    width: 94.7%;
}

.works_orange {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #FA541C;
    margin-left: 1%;
}

.works_blue {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #22B8CF;
    margin-right: 1%;
}

.works_yellow {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #FFC81A;
    margin-left: 1%;
}

.works_green {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #0CD66E;
    margin-right: 1%;
}

.planning_wrapper_green {
    display: flex;
    justify-content: center;
    width: 75%;
    padding-bottom: 150px;
}

.planning_wrapper_green div {
    margin-right: 2%;
}

.planning_wrapper_green h3 {
    margin-left: 65%;
}

.planning_wrapper_blue {
    display: flex;
    justify-content: center;
    width: 75%;
}

.planning_wrapper_blue div {
    margin-right: 2%;
}

.planning_wrapper_blue h3 {
    margin-left: 59%;
}

.planning_wrapper_orange {
    display: flex;
    justify-content: center;
    width: 122%;
}

.planning_wrapper_orange div {
    margin-left: 2%;
}

.planning_wrapper_yellow {
    display: flex;
    justify-content: center;
    width: 122%;
}

.planning_wrapper_yellow div {
    margin-left: 2%;
}

.work_slide_wrapper {
    background: #F9FAFB;
}

.header_slide {
    text-align: center;
    padding-top: 120px;
}

.header_slide span {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #919EAB;
}

.header_slide h2 {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #212B36;
    margin-top: 16px;
}

.work_slider {
    text-align: center;
    font-size: 50px;
    margin-top: 150px;
    padding-bottom: 100px;
}

.posts_wrapper h2 {
    margin-top: 120px;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #212B36;
}

.posts_slider {
    margin-top: 80px;
    padding-bottom: 200px;
    text-align: center;
    font-size: 60px;
}

.form_wrapper {
    width: 100%;
    height: 624px;
    background: url("../../assets/bg/bg_form.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.form_content {
    padding-top: 140px;
    display: flex;
    justify-content: space-between;
}

.form_content_header h2 {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
    color: #FA541C;
    padding-bottom: 65px;
}

.form_icon_text {
    display: flex;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    padding-bottom: 15px;
}

.form_icon_text p {
    padding-left: 10px;
}

.form_input input{
    display: block;
    margin-bottom: 20px;
    background: rgba(145, 158, 171, 0.08);
    border-radius: 8px;
    border: none;
    padding: 14px 370px 16px 12px;
}

.form_input input::placeholder {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #919EAB;
}

.form_input button {
    margin-top: 20px;
    color: #FFFFFF;
    padding: 11px 33px;
    background: #FA541C;
    border-radius: 8px;
    border: none;
}

.sign_wrapper {
    background: #F9FAFB;
    border-bottom: 2px solid rgba(145, 158, 171, 0.24);;
}

.form_sign_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 65px 0;
    align-items: center;
}

.form_sign_icon_text {
    display: flex;
    align-items: center;
}

.form_sign_icon_text img {
    margin-right: 24px;
}

.form_sign_icon_text h3 {
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #212B36;
    padding-bottom: 8px;
}

.form_sign_icon_text p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #637381;
}

.sign_input_button input {
    background: rgba(145, 158, 171, 0.08);
    border-radius: 8px 0px 0px 8px;
    border: none;
    padding: 15px 280px 15px 12px;
}

.sign_input_button input::placeholder {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #919EAB;
}

.sign_input_button button {
    color: #FFFFFF;
    background: #FA541C;
    border-radius: 0px 8px 8px 0px;
    border: none;
    padding: 15px 22px;
}

@media (max-width: 1200px) {
    .search_form_button {
        margin-left: 10%;
    }

    .works_step_blue {
        width: 92%;
    }

    .works_step_yellow {
        width: 102%;
    }

    .works_step_green {
        width: 92%;
    }

    .planning_wrapper_orange {
        width: 134%;
    }

    .planning_wrapper_blue {
        width: 61%;
    }

    .planning_wrapper_yellow {
        width: 134%;
    }
    
    .planning_wrapper_green {
        width: 61%;
    }
}

@media (max-width: 960px) {
    .search_form_button {
        margin-left: 5%;
    }

    .service_content_button {
        width: 60%;
    }

    .management_wrapper {
        align-items: center;
    }

    .circle_wrapper h2{
       width: 60%;
    }

    .circle_wrapper_center_left h2 {
        width: 50%;
    }

    .works_step_green {
        width: 90%;
    }
    .works_step_blue {
        width: 90%;
    }

    .planning_wrapper_orange {
        width: 142%;
    }

    .planning_wrapper_blue {
        width: 51%;
    }

    .planning_wrapper_yellow {
        width: 142%;
    }
    
    .planning_wrapper_green {
        width: 51%;
    }
}

@media (max-width: 876px) {
    .search_form_button {
        margin-left: 3%;
    }
    
    .service_content_button {
        width: 75%;
    }

    .circle_wrapper_center_left h2 {
        width: 55%;
    }

    .works_step_green {
        width: 89.5%;
    }
    .works_step_blue {
        width: 89.5%;
    }

    .planning_wrapper_orange {
        width: 146%;
    }

    .planning_wrapper_blue {
        width: 48%;
    }

    .planning_wrapper_yellow {
        width: 146%;
    }
    
    .planning_wrapper_green {
        width: 48%;
    }
}

@media (max-width: 768px) {

    .search_form_button {
        margin-left: -5%;
        width: 640px;
    }
    .search_form_button input {
        padding-right: 5%;
        padding-left: 10%;
    }

    .service_img_website {
        right: 330px;
    }

    .service_img_divider {
        left: 268px;
    }

    .service_content_wrapper {
       flex-direction: column;
       text-align: center;
    }

    .service_content_wrapper div ul li {
        list-style: none;
    }

    .service_content_button {
        text-align: center;
        width: 33%;
        margin-left: 35%;
    }

    .works_step_green {
        width: 88.5%;
    }
    .works_step_blue {
        width: 88.5%;
    }

    .planning_wrapper_orange {
        width: 152.5%;
    }

    .planning_wrapper_blue {
        width: 48%;
    }

    .planning_wrapper_blue h3 {
        margin-left: 53%;
    }

    .planning_wrapper_yellow {
        width: 152.5%;
    }
    
    .planning_wrapper_green {
        width: 48%;
    }

    .planning_wrapper_green h3 {
        margin-left: 53%;
    }
}

@media (max-width: 660px) {
    .search_form_button {
        margin-left: -8%;
    }
}

@media (max-width: 650px) {
    .text_wrapper {
        margin-top: 100px;
    }

    .search_form_button {
       width: 100%;
       background: none;
       margin-left: 1%;
    }

    .service_img_divider {
       display: none;
    }

    .search_form_button input {
        display: block;
        background: rgba(255, 255, 255, 0.205);
        backdrop-filter: blur(4px);
        border-radius: 8px;
        padding: 15px 0 15px 62px;
        width: 100%;
    }

    .service_input_website {
        margin-top: 20px;
    }

    .service_button_analyse {
        width: 100%;
        margin-top: 20px;
    }
}

@media (max-width: 375px) {
    .text_wrapper {
        margin-top: 0;
    }

    .service_img_website {
        left: 30px;
        padding-top: 70px;
    }

    .service_img_email {
        padding-top: 5px;
        left: 30px;
    }

    .service_img_artboard {
        width: 295px;
        height: 295px;
    }

    .service_content_button {
        width: 70%;
        margin-left: 15%;
    }

    .service_include_icons {
       flex-direction: column;
       margin-left: 30%;
    }

    .service_include_icons div img {
        width: 100%;
    }

    .service_include_icons div h3 {
        width: 150%;
        margin-left: -25%;
    }

    .service_include_icons div p {
        width: 150%;
        margin-left: -25%;
    }

    .management_wrapper {
        flex-direction: column;
    }

    .management_wrapper img{
        display: none;
    }

    .circle_wrapper_center_left {
        margin-left: 0;
    }

    .circle_text_center_left {
        margin-left: 0;
    }

    .circle_wrapper_center_right {
        margin-left: 0;
    }

    .circle_text_center_right {
        margin-left: 0;
    }

    .get_wrapper{
        flex-direction: column;
    }
    .footer_bg{
        padding: 80px 0px ;
    }
    .get_title{
        font-size: 40px;
        line-height: 50px;
        text-align: center;
    }
    .get_email{
        text-align: center;
        font-size: 15px;
        width: 100%;
        margin: 0;
    }
    .get_email::before{
        left: 60px;
    }

    .location::before{
        left: -5px;
    }
    .request{
        width: 100%;
        margin-top: 40px;
    }

    .get_input{
        width: 100%;
    }
    .get_button{
        display: block;
        margin: 0 auto;
    }

    .sign_wrapper{
        flex-direction: column;
    }
    .new{
        flex-direction: column;
        align-items:center;
        margin-bottom: 32px;
    }
    .sign_up_input{
        width: 216px;
        height: 56px;
        background: rgba(145, 158, 171, 0.08);
        border-radius: 8px 0px 0px 8px;
    }
    .sign_up_btn{
        width: 102px;
        height: 56px;
        border-radius: 0px 8px 8px 0px;
    }
    .footer_wrapper{
        flex-direction: column;
        gap: 30px;
    }

    .footer_zone{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .email_input{
        width: 260px;
    }
    .email_btn{
        margin: 0;
    }

    .black_email::before{
        left: 40px;
    }
    .black_location::before{
        left: -20px;
    }
    .footer_rights{
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-top: 24px;
    }
}