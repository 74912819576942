.blog_details_bg{
    width: 100%;
    height: 616px;
    background: url('../../assets/bg/blog_details_bg.svg') no-repeat center center/ cover;
}

.blog_details_links{
    width: 100%;
    height: 72px;
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
    display: flex;
    align-items: center;
    gap: 32px;
    padding-left: 144px;
}

.link_name{
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #212B36;
    position: relative;
    cursor: pointer;
}

.link_name::after{
    position: absolute;
    content: '';
    width: 6px;
    height: 10px;
    top: 8px;
    right: -20px;
    background: url('../../assets/icons/details_vestor.svg')
}

.third_link{
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #919EAB;
    cursor: pointer;
}

.blog_details_wrapper{
    width: 760px;
    margin: 0 auto;
    padding: 80px 0;
}

.blog_date{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #919EAB;
    text-align: center;
}

.blog_title{
    font-weight: 700;
    font-size: 44px;
    line-height: 64px;
    text-align: center;
    color: #212B36;
    margin-top: 24px;
}

.blog_descr{
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #212B36;
    margin-top: 24px;
    text-align: center;
}

.blog_commentator{
    height: 100px;
    width: 100%;
    border-top: 1px solid rgba(145, 158, 171, 0.24);
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog_commentator_name{
    display: flex;
    gap: 12px;
}

.name{
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #212B36;
}

.date{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #637381;
    margin-top: 4px;
}

.icons{
    width: 72px;
    display: flex;
    gap: 20px;
}

.blog_paragraph{
    display: flex;
    align-items: center;
    margin-top: 48px;
    gap: 16px;
    height: 84px;
}

.first_letter{
    font-weight: 700;
    color: #212B36;
    font-size: 80px;
}

.paragraph_content{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #212B36;
    margin-bottom: 40px;
}

.paragraph_title{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #212B36;
    margin-bottom: 16px;
}


.donec_wrapper{
    display: flex;
    margin: 80px 0;
    gap: 46px;
    padding-left: 46px;

}

.donec_title{
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #212B36;
}

.tags{
    display: flex;
    margin-top: 48px;
    gap: 8px;
}

.tag{
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #212B36;
}

.tags_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    background: rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #637381;
}

.share{
    margin-top: 52px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.share_item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    gap: 8px;
    width: 111px;
    height: 30px;
    border: 1px solid #1877F2;
    border-radius: 8px;
}

.share_item_title{
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #1877F2;
}

.courtney_wrapper{
    display: flex;
    align-items: flex-start;
    gap: 32px;
    margin-top: 68px;
    padding-top: 64px;
    border-top: 1px solid rgba(145, 158, 171, 0.24);
}

.henry_wrapper{
    display: flex;
    justify-content: space-between;
    align-items:center;
    gap: 315px;
}

.henry_name{
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: #212B36;
}

.henry_position{
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #637381;
    margin-top: 4px;
}

.henry_descr{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #637381;
    margin-top: 16px;
}

.henry_date{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #919EAB;
    margin-top: 16px;
}

hr{
    background: rgba(145, 158, 171, 0.24);
    border: none;
    height: 1px;
}
