.wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
}

.digital{
    width: 432px;
}

.digital_title {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FA541C;
}

.digital_big_title{
    font-weight: 700;
    font-size: 55px;
    line-height: 80px;
    color: #212B36;
    margin-top: 24px;
}

.digital_descr{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #637381;
    margin-top: 42px;
}

.digital_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 315px;
    margin-top: 50px;
}

.free_btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 22px;
    width: 132px;
    height: 48px;
    background: #FA541C;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FFFFFF;
    border: none;
}

.brands{
    width: 80%;
    margin: 200px auto 80px auto;
    display: flex;
    justify-content: space-between;
}


.background{
    border-radius: 10px;
    width: 100%;
}

.about {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}

.about_us {
    width: 41%;
}

.about_title{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #919EAB;
    text-align: right;
}

.about_big_title{
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #212B36;
    margin-top: 16px;
    text-align: right;
}

.about_descr{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: right;
    color: #637381;
    margin-top: 24px;
}

.learn{
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FA541C;
    margin-top: 51px;
    text-align: right;
    margin-right: 50px;
    position: relative;
    cursor: pointer;
}

.learn::after{
    position: absolute;
    content: ' ';
    width: 17px;
    height: 10px;
    top: 8px;
    right: -33px;
    background: url('../../assets/icons/right.svg');
}

.project{
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.statics{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statics_count{
    width: 35%;
    position: relative;
}

.statics_count::after{
    content: '';
    position: absolute;
    width: 1px;
    left: calc(72% - 1px/2);
    top: 0px;
    bottom: 0px;
    background: rgba(145, 158, 171, 0.24);
}

.statics_count_number{
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #212B36;
    position: relative;
}

.statics_count_number::after{
    position: absolute;
    content: '+';
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #FA541C;
    top: 0;
    left: 90px;
}

.statics_count_number::after:last-child{
    left: 80px;
}

.statics_count_title{
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #919EAB;
    width: 100px;
}

.statics_descr{
    width: 70%;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #637381;
}

.services{
    margin-top: 120px;
}


.services_wrapper{
    margin-top: 94px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
}

.services_card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 32px;
    gap: 40px;
    background: #FFFFFF;
    box-shadow: -8px 8px 24px -4px rgba(145, 158, 171, 0.1);
    border-radius: 16px;
}

.services_card:nth-child(2) {
    padding: 64px 32px;
    box-shadow: -16px 16px 56px -8px rgba(145, 158, 171, 0.16);
}

.services_card:nth-child(3) {
    padding: 80px 32px;
    box-shadow: -24px 24px 72px -8px rgba(145, 158, 171, 0.24);
}

.services_card:hover{
    box-shadow: -24px 24px 72px -8px rgba(145, 158, 171, 0.24);
    border-radius: 16px;
    transition: all 0.4s;
}

.services_card_title{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #212B36;
    height: 56px;
}

.services_card_descr{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #637381;
}

.right_btn{
    background: none;
    border: none;
    cursor: pointer;
    height: 40px;
}

.right_btn:hover{
    width: 40px;
    height: 40px;
    background: rgba(250, 84, 28, 0.08);
    border-radius: 100%;
    transition: all 0.5s;
}

.working{
    margin-top: 120px;
}

.working_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
}

.working_card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 24px;
    width: 264px;
    height: 150px;
    background: #FDAB76;
    box-shadow: -8px 12px 32px rgba(250, 84, 28, 0.2);
    border-radius: 16px;
}

.working_card_text{
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    margin-left: 150px;
    position: relative;
    color: #02665B;
}

.working_card_text::after{
    position: absolute;
    content: " ";
    top: 5px;
    right: -33px;
    width: 22px;
    height: 22px;
}

.text_orange{
    color: #770508;
    margin-left: 110px;
}

.text_orange::after{
    background: url('../../assets/icons/planning_right.svg');
}

.text_blue{
    color: #063963;
    margin-left: 100px;
}
.text_blue::after{
    background: url('../../assets/icons/researxh_right.svg');
}

.text_yellow{
    color: #7A5204;
    margin-left: 80px;

}
.text_yellow::after{
    background: url('../../assets/icons/optimize_rigth.svg');
}

.blue{
    background: #77F0ED;
    box-shadow: -8px 12px 32px rgba(34, 184, 207, 0.2);
    margin-bottom: 40px;
}

.yellow{
    background: #FFE475;
    box-shadow: -8px 12px 32px rgba(255, 200, 26, 0.2);
    margin-bottom: 80px;
}

.green{
    background: #69F290;
    box-shadow: -8px 12px 32px rgba(12, 214, 110, 0.2);
    margin-bottom: 120px;
}

.case{
    margin-top: 120px;
}

.case_wrapper{
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.marketing{
    width: 172px;
    height: 229.33px;
    background: url('../../assets/bg/marketing_adap.svg') no-repeat center center / cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 20px;
}

.web_bg{
    background: url('../../assets/bg/web_adap.svg')
}


.branding_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.bank_wrapper{
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    gap: 45px;
}

.bank{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 564px;
    height: 372px;
    background: #FFFFFF;
    box-shadow: -24px 24px 72px -8px rgba(145, 158, 171, 0.24);
    border-radius: 16px;
}

.seo{
    width: 172px;
    height: 172px;
    background: url('../../assets/bg/seo_bg.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}


.bank_america{
    padding: 40px 10px 0 30px;
}

.bank_america_subtitle{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FA541C;
}

.bank_america_title{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #212B36;
    margin-top: 8px;
}

.bank_america_descr{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #637381;
    margin-top: 16px;

}

.bank_america_learn{
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #637381;
    margin: 124px 0 0 120px;
    position: relative;
    cursor: pointer;
}

.bank_america_learn::after{
    position: absolute;
    content: '';
    top: 2px;
    right: 16px;
    width: 18px;
    height: 18px;
    background: url('../../assets/icons/bank_right.svg')
}

.louis{
    align-items: flex-start;
}

.louis_wrapper{
    padding: 6px;
}

.gillette{
    background: url('../../assets/bg/gilette_bg.svg')
}

.view{
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FA541C;
    margin: 130px 40px 0 0 ;
    text-align: right;
    position: relative;
    cursor: pointer;
}

.view::after{
    position: absolute;
    content: '';
    top: 7px;
    right: -33px;
    width: 17px;
    height: 10px;
    background: url('../../assets/icons/right.svg')
}

.meet{
    width: 100%;
    min-height: 752px;
    background: url('../../assets/bg/meet_Background.svg');
    margin-top: 130px;
    position: absolute;
    left: 0;
    padding: 160px 0 156px 144px;
    display: flex;
    justify-content: space-between;
}

.meet_wrapper{
    width: 29%;
}

.meet_subtitle{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #637381;
    opacity: 0.48;
}

.meet_title{
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #FFFFFF;
    margin-top: 16px;
}

.meet_descr{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 24px;
}

.btns{
    display: flex;
    margin-top: 192px;
    gap: 10px;
}

.circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(250, 84, 28, 0.24);
    border-radius: 50px;
    cursor: pointer;
}

.circle:hover{
    background: rgba(250, 84, 28, 0.08);
} 

.meet_team{
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.team_card_name{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 20px;
    text-align: center;
}

.team_card_pfoss{
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #919EAB;
    margin-top: 4px;
    text-align: center;
}

.check{
    margin-top: 1020px;
    z-index: 4;
    position: relative;
}

.check_monthly{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #212B36;
    text-align: right;
}

.switch {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 20px;
    margin: 0 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #FA541C;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #FA541C;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }

  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.check_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
}

.check_card{
    padding: 64px 40px 40px;
    gap: 40px;
    width: 362px;
    height: 524px;
    background: #FFFFFF;
    box-shadow: -4px 4px 24px rgba(145, 158, 171, 0.08);
    border-radius: 16px;
}

.check_card_title{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #FA541C;
    position: relative;
}
.check_card_title::after{
    position: absolute;
    content:'';
    top: 0;
    right: 0;
    width: 64px;
    height: 64px;
}
.check_card_price{
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #212B36;
    margin-top: 16px;
}

.month{
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    color: #919EAB;
}

.check_card_descr{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #637381;
    margin-top: 24px;
}

.check_card_btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 22px;
    width: 282px;
    height: 48px;
    background: #FA541C;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FFFFFF;
    border: none;
    margin-top: 40px;
    cursor: pointer;
}

.check_card_ul{
    margin-top: 40px;
}
.check_card_li{
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #212B36;
    margin-bottom: 16px;
    list-style-image: url('../../assets/icons/li_dot.svg');
    margin-left: 20px;
    list-style-type: disc;
    padding-left: 16px;
}

.basic::after{
    background: url('../../assets/img/airplain.svg')
}

.standart::after{
    background: url('../../assets/img/standart_rocket.svg')
}

.premium::after{
    background: url('../../assets/img/premium_rocket.svg')
}

.standart_card{
    height: 566px;
    box-shadow: -16px 16px 56px -8px rgba(145, 158, 171, 0.16);
}

.premium_card{
    height: 608px;
    box-shadow: -24px 24px 72px -8px rgba(145, 158, 171, 0.24);
}





@media (max-width: 375px) {
    .digital{
        width: 100%;
    }
    .digital_title{
        text-align: center;
        font-size: 12px;
    }
    .digital_big_title{
        text-align: center;
        font-size: 40px;
        line-height: 50px;
    }
    .digital_descr{
        text-align: center;
        font-size: 16px;
        line-height: 28px;
    }
    .digital_img{
        display: none;
    }
    .brands{
        overflow: scroll;
        gap: 70px;
    }
    .about{
        flex-direction: column;
    }
    .about_title{
        text-align: center;
        font-size: 12px;
        line-height: 20px;
    }
    .about_big_title{
        font-size: 32px;
        line-height: 42px;
        margin-top: 16px;
        text-align: center;
    }
    .about_descr{
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        margin-top: 24px;
    }

    .about_us{
        width: 100%;
    }
    .learn{
        text-align: center;
    }
    .learn::after{
        right: 61px;
    }

    .project{
        width: 100%;
        margin-top: 30px;
    }

    .statics_count_number{
        font-size: 32px;
    }

    .statics_count_number::after{
        top: 9px;
        left: 60px;
    }
    .statics_count_title{
        font-size: 12px;
    }

    .statics{
        margin-bottom: 30px;
    }

    .statics_descr{
        font-size: 14px;
        line-height: 26px;
        width: 50%;
    }
    .statics_count{
        width: 50%;
    }

    .services_wrapper{
        flex-direction: column;
    }

    .working_wrapper{
        flex-direction: column;
        gap: 32px;
    }
    .working_card{
        margin: 0;
    }

    .case_wrapper{
        flex-direction: column;
        gap: 32px;
        margin-top: 64px;
    }

    .marketing{
        width: 100%;
        height: 335px;
    }
    .branding_wrapper{
        display: none;
    }
    .view{
        text-align: center;
    }

    .view::after{
        right: 80px;
    }
    .meet{
        flex-direction: column;
        padding: 80px 20px;
    }
    .meet_wrapper{
        width: 100%;
    }

    .meet_subtitle{
        font-size: 12px;
        line-height: 20px;
        text-align: center;
    }
    .meet_title{
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        color: #FA541C;
        margin-top: 16px;
    }
    .meet_descr{
        font-size: 16px;
        line-height: 28px;
        margin-top: 24px;
        text-align: center;
    }
    .meet_team{
        margin-top: 64px;
        width: 100%;
        overflow: scroll;
    }
    .btns{
        display: none;
    }

    .team_card{
        width: 100%;
        display: none;
    }
    .check_monthly{
        text-align: center;
        margin-top: 40px;
    }
    .check_wrapper{
        flex-direction: column;
        margin-top: 40px;
        gap: 32px;
    }

    .faq_wrapper{
        flex-direction: column;
    }
    
}


