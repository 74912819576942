.faq{
    margin-top: 120px;
    padding-bottom: 120px;
}

.faq_wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}


.questions{
    margin-top: 20px;
    display: flex;
}

.question{
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #212B36;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
    width: 564px;
}

.question_btn {
    border: none;
    background: none;
    text-align: right;
    color: #212B36;
    font-size: 18px;
    margin-left: -20px;
    height: 20px;
    cursor: pointer;
}

.question_img{
    display: block;
    margin-top: -200px;
}

.answer{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #212B36;
    margin-top: 20px;
}

@media (max-width: 375px) {
    .question_img{
        display: none;
    }

    .question{
        width: 100%;
    }
}