.nav{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
}
.scrolled{
    background: #fff ;
    box-shadow: 0 8px 24px 0 rgba(52,53,57,.2);
}
.wrapper{
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.burger_menu{
    display: none;
}
.nav_menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
}

.menu_sign{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 274px;
    position: relative;
}

.menu_link{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #637381;
    text-decoration: none;
}

.login{
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #212B36;
    cursor: pointer;
}

.join_btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    height: 36px;
    background: #212B36;
    border-radius: 8px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
}

.down_wrapper{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80%;
    left: 28.5%;
    padding: 15px;
    border-radius: 15px;
    background-color: #fff;
    border: 1px solid gray;
    z-index: 3;
    padding: 25px;
}

.down_link{
    text-decoration: none;
    text-align: center;
    color: #637381;
    font-weight: 400;
    font-size: 16px;
}

.down_link:hover{
    color: #c3c8cc;
}

.menu_page{
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #212B36;
    cursor: pointer;
    position: relative;
}
.menu_page::before{
    position: absolute;
    content: ' ';
    width: 6px;
    height: 6px;
    background: #FA541C;
    top: 9px;
    left: -16px;
    border-radius: 100%
}

.down_ul{
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 5px;
}

.lng_btns{
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 42px;
    right: 158px;
    width: 110px;
    height: 60px;
    border-radius: 10px ;
    background: none;
}


.lng_btn{
    border: none;
    cursor: pointer;
    height: 30px;
    border-radius: 10px;
}

.navbar{
    display: flex;
    justify-content: space-between;
    width: 77%;
}

.navbar_menu{
    display: none;
}

@media (max-width: 375px) {
    .navbar{
        width: 80%;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.767);
        position: absolute;
        left: 0;
        top: 0;
        padding: 100px 20px;
        transform: translateX(0);
    }
    
    .navbar_active{
        transform: translateX(-100%);
    }

    .menu_link, .menu_page{
        color:#fff;
    }
    .navbar_menu{
        display: block;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .menu_sign{
        display: none;
    }
    .burger_menu{
        display: block;
        display: flex;
        flex-direction: column;
        gap: 3px;
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .burger_menu span {
        width: 18px;
        height: 2px;
        background: #000;
    }
    .menu_logo{
        position: absolute;
        left: 20px;
        top: 20px;
    }
}